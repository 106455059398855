import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from '../../../../views/component-base';
import { MemberPortalSharedState } from '../../store/shared.states';
import { AppState } from 'src/app/store/app.states';
import { noticeOfIntentTaxDeduction_Applications, noticeOfIntentTaxDeduction_CurrentStep, noticeOfIntentTaxDeduction_Form, noticeOfIntentTaxDeduction_FormComponentData } from './selectors';
import { memberAccountDropdown_SelectedAccount } from '../member-account-dropdown/selectors';
import { combineLatest, distinct } from 'rxjs';
import { GetFormDataAction, RequestAction, ResetComponentStateAction, SetCurrentStepAction } from './actions';
import { NoticeOfIntentTaxDeductionModel } from 'src/app/model/notice-of-intent-tax-deduction.model';

@Component({
  selector: 'app-notice-of-intent-tax-deduction-container',
  templateUrl: './notice-of-intent-tax-deduction-container.component.html',
  styleUrls: ['./notice-of-intent-tax-deduction-container.component.scss']
})

export class NoticeOfIntentTaxDeductionContainerComponent extends ComponentBase implements OnInit, OnDestroy {

  currentStep$ = this.store.pipe(select(noticeOfIntentTaxDeduction_CurrentStep));
  applications$ = this.store.pipe(select(noticeOfIntentTaxDeduction_Applications));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  formComponentData$ = this.store.pipe(select(noticeOfIntentTaxDeduction_FormComponentData));
  form$ = this.store.pipe(select(noticeOfIntentTaxDeduction_Form));

  applications: NoticeOfIntentTaxDeductionModel[];
  accountId: number;

  constructor(public store: Store<MemberPortalSharedState>,
    public appstore: Store<AppState>) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.sub = combineLatest([this.selectedAccount$, this.currentStep$]).subscribe(x => {
      if (x?.[0]?.accountId) {
        // if we are on current step 1, get submitted applications
        if (x[1] === 1) {
          this.accountId = x?.[0].accountId;
          this.dispatch(this.store, RequestAction({ accountId: this.accountId }));
        }
      }
    });

    this.sub = this.applications$.subscribe(x => {
      if (x) {
        this.applications = x;
      }
    })
  }

  ngOnDestroy() {
    this.dispatch(this.store, ResetComponentStateAction());
    super.ngOnDestroyBase();
  }

  startApplication() {
    this.dispatch(this.store, GetFormDataAction({ accountId: this.accountId }))
  }

  continue() {
    this.dispatch(this.store, SetCurrentStepAction({ currentStep: 3 }))
  }

  cancel() {
    this.dispatch(this.store, SetCurrentStepAction({ currentStep: 1 }))
  }
}

