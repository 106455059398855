import { MessagePipe } from './modules/shared/pipes/message';

import { DashboardComponent } from './views/dashboard/dashboard.component';
import { CalculatorViewComponent } from './views/calculator/calculator-view.component';
import { OtherDocumentsViewComponent } from './views/other-documents/other-documents-view.component';
import { DocumentsService } from './services/documents.service';
import { UnitPricesComponent } from './views/unit-prices/unit-prices.component';
import { MemberStatementsViewComponent } from './views/member-statements/member-statements-view.component';
import { UnitPriceService } from './services/unit-price.service';
import { AssetsViewComponent } from './views/assets-view/assets-view.component';
import { PersonalContributionViewComponent } from './views/personal-contributions-view/personal-contributions-view.component';
import { ContributionsViewComponent } from './views/contributions-view/contributions-view.component';
import { RollInViewComponent } from './views/rollin-view/rollin-view.component';
import { SwitchInvestmentsViewComponent } from './views/switch-investments-view/switch-investments-view.component';
import { SwitchInvestmentsService } from './services/switch-investments.service';
import { InsuranceViewComponent } from './views/insurance-view/insurance-view.component';
import { HistoricalDataService } from './services/historical-data.service';
import { ShowQuestionnaireGuardService } from './route-guard/show-questionnaire-guard.service';
import { InsuranceQuestionnaireService } from './services/insurance-questionnaire.service';
import { QuestionnaireViewComponent } from './views/questionnaire/questionnaire-view.component';
import { TransactionListViewComponent } from './views/transaction-list/transaction-list.component';
import { BeneficiaryEditComponent } from './views/beneficiary-edit/beneficiary-edit.component';
import { PersonalEditComponent } from './views/personal-edit/personal-edit.component';
import { ChangePasswordComponent } from './views/change-password/change-password.component';
import { PersonalDetailsComponent } from './views/personal-details/personal-details.component';
import { LoginErrorPageComponent } from './views/login/login-error.component';
import { MemberNoteTypeService } from './services/member-note-type.service';
import { CostOfCoverService } from './services/costofcover.service';
import { UnderlyingInvestmentService } from './services/underlying-investment.service';
import { WebUserService } from './services/webuser.service';
import { MemberCorrespondenceService } from './services/member-correspondence.service';
import { InvestmentService } from './services/investment.service';
import { TransactionService } from './services/transaction.service';
import { BeneficiaryService } from './services/beneficiary.service';
import { InsuranceService } from './services/insurance.service';
import { RolesService } from './services/roles.service';
import { PermissionGuardService } from './route-guard/permission-guard.service';
import { CommonEffects } from './store/common/common.effects';
import { HelperService } from './services/helper.service';
import { AccountsService } from './services/accounts.service';
import { HttpCustomInterceptor } from './services/http-interceptor';
import { BrowserModule, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule, MetaReducer } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgrxFormsModule } from 'ngrx-forms';
import { reducers } from './store/app.reducers';
import { environment } from 'src/environments/environment';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './app.material.module';
import { ToastrModule } from 'ngx-toastr';
import { MembersService } from './services/members.service';
import { TimeagoModule } from 'ngx-timeago';
import { clearState } from './store/meta-reducer';
import { RollInService } from './services/rollin.service';
import Bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import { IfaaUiComponentsModule } from '@ifaa-components/ui-components';
import { TransactionsComponent } from './views/transactions/transactions.component';
import { GetTenantModule } from './tenant.helper';
import { ChatService } from './services/chat.service';
import { MemberPortalSharedModule } from './modules/shared/shared.module';
import { NotificationService } from './services/notification.service';
import { BeneficiaryViewComponent } from './views/beneficiary-view/beneficiary-view.component';
import { PersonalContributionService } from './services/personal-contrubution.service';
import { ChangePasswordEffects } from './views/change-password/effects';
import { UserServiceConfig } from '@ifaa-components/ui-components/lib/services/userServiceConfig';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { ElevatedAccessComponent } from './views/elevated-access/elevated-access.component';
import { FeatureFlagGuardService } from './route-guard/feature-flag-guard.service';

import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { BPAYDetailsService } from './services/bpay-details.service';
import { MakeContributionViewComponent } from './views/make-a-contribution-view/make-a-contribution-view.component';
import { MemberPensionService } from './services/member-pension.service';
import { PensionViewComponent } from './views/pension-view/pension-view.component';
import { PensionGuardService } from './route-guard/pension-guard.service';
import { InvestmentEditViewComponent } from './views/investment-view/investment-view.component';
import { AccumGuardService } from './route-guard/accum-guard.service';
import { AccessYourSuperViewComponent } from './views/access-your-super-view/access-your-super-view.component';
import { AccessYourSuperService } from './services/access-your-super.service';
import { AccessYourSuperCreateComponent } from './views/access-your-super-create/access-your-super-create.component';
import { UnderlyingInvestmentViewComponent } from './views/underlying-investment-view/underlying-investment-view.component';
import { LetModule, PushModule } from '@ngrx/component';
import { EmploymentService } from './modules/smrt/services/employment.service';
import { TakeMeWithYouViewComponent } from './views/take-me-with-you-view/take-me-with-you-view.component';
import { TakeMeWithYouEffects } from './views/take-me-with-you-view/effects';
import { TakeMeWithYouService } from './services/take-me-with-you.service';

import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import {
  MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalService,
  MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, ProtectedResourceScopes, MsalModule
} from '@azure/msal-angular';
import { msalConfig, msalElevatedConfig, loginRequest, protectedResources } from './auth-config';
import { AccountFeatureToggleGuardService } from './route-guard/account-feature-toggle-guard.service';
import { AccountDataGuardService } from './route-guard/account-data-guard.service';
import { FeatureToggleService } from './services/feature-toggle.service';
import { MemberFeatureToggleGuardService } from './route-guard/member-feature-toggle-guard.service';
import { MaintenancePageComponent } from './views/maintenance-page/maintenance-page.component';
import { BlackoutGuardService } from './route-guard/blackout-guard.service';
import { ErrorPageComponent } from './views/error-page/error-page.component';
import { ErrorPageGuardService } from './route-guard/error-page-guard.service';
import { NotAuthGuardService } from './route-guard/not-auth-guard.service';
import { Injector } from '@angular/core';
import { CommomService } from './views/component-base';
import { MsalCheckService } from './route-guard/msal-check.service';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { FeatureToggleCheckService } from './helper/featureToggleHelper';
import { Idle, NgIdleModule } from '@ng-idle/core';
import { AccessYourSuperReviewSubmittedComponent } from './views/access-your-super-review-submitted/access-your-super-review-submitted.component';
import { NoticeOfIntentToClaimTaxDeductionViewComponent } from './views/notice-of-intent-tax-deduction/notifice-of-intent-tax-deduction-view.component';
import { NoticeOfIntentTaxDeductionService } from './services/notice-of-intent-tax-deduction.service';
import { NoiPendingWithdrawalDialog } from './modules/shared/components/notice-of-intent-tax-deduction-form/noi-pending-withdrawal-dialog/noi-pending-withdrawal-dialog.component';
import { TRANSLATE_MODULE_CONFIG } from './translateHttpLoader';

export const metaReducers: MetaReducer<any>[] = [clearState];
Bugsnag.start({
  apiKey: environment.bugsnagApiKey,
  releaseStage: environment.bugsnagEnv,
  enabledReleaseStages: ['Production', 'Staging', 'Uat', 'unknown'],
  onError: function (event) {
    event.addMetadata('tenant', {
      tenant: environment.code
    })
  }
});

export let AppInjector: Injector;

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

export function errorHandlerFactory() {
  return new BugsnagErrorHandler()
}

function inIframe() {
  return window.location !== window.parent.location;
}

export function MSALInstanceFactory(): IPublicClientApplication {
  if (inIframe()) {
    return new PublicClientApplication(msalElevatedConfig);
  }

  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {

  const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();

  protectedResourceMap.set(protectedResources.apiEndpoint.endpoint.replace('api/**', 'assets/**'), null);
  protectedResourceMap.set(protectedResources.apiEndpoint.endpoint.replace('**', 'helper/systemconfig'), null);
  protectedResourceMap.set(protectedResources.apiEndpoint.endpoint.replace('**', 'web-user/isMaintenanceMode'), null);
  protectedResourceMap.set(protectedResources.apiEndpoint.endpoint, [
    {
      httpMethod: 'GET',
      scopes: [...protectedResources.apiEndpoint.scopes.read]
    },
    {
      httpMethod: 'POST',
      scopes: [...protectedResources.apiEndpoint.scopes.write]
    },
    {
      httpMethod: 'PUT',
      scopes: [...protectedResources.apiEndpoint.scopes.write]
    },
    {
      httpMethod: 'DELETE',
      scopes: [...protectedResources.apiEndpoint.scopes.write]
    }
  ]);

  return {
    interactionType: inIframe() ? InteractionType.Popup : InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {

  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest
  };
}
const disableAnimations =
  !('animate' in document.documentElement)
  || (navigator && /iPhone OS (8|9|10|11|12|13)_/.test(navigator.userAgent));

const myImports = [
  NgrxFormsModule,
  CommonModule,
  HammerModule,
  FormsModule,
  BrowserAnimationsModule.withConfig({ disableAnimations }),
  PdfJsViewerModule,
  RecaptchaV3Module,
  LetModule, PushModule,
  IfaaUiComponentsModule.forRoot({
    entryAppearance: 'outline',
    showEntryPlaceholder: false,
    showButtonIcon: environment.showButtonIcon,
    apiUrl: environment.apiUrl,
    entryFileAttachedIcon: environment.entryFileAttachedIcon,
    entryFileDefaultIcon: environment.entryFileDefaultIcon,
    buttonTitleTextTransform: environment.buttonTitleTextTransform,
    titleTextTransform: environment.titleTextTransform,
    greenIdConfig: {
      environment: environment.greenIdEnv,
      code: environment.greenIdCode,
      iconVerified: environment.greenIdVerifiedIcon,
      iconNotVerified: environment.greenIdNotVerifiedIcon
    },
    tenantCode: environment.code
  } as UserServiceConfig),
  MemberPortalSharedModule,
  ToastrModule.forRoot({
    timeOut: 6000,
    positionClass: 'toast-bottom-right',
    preventDuplicates: true,
  }),
  MaterialModule,
  HttpClientModule,
  TimeagoModule.forRoot(),
  BrowserAnimationsModule,
  AppRoutingModule,
  TranslateModule.forRoot(TRANSLATE_MODULE_CONFIG),
  StoreModule.forRoot(reducers, { metaReducers }),
  StoreDevtoolsModule.instrument({
    maxAge: 20, // Retains last 25 states
    logOnly: environment.production, // Restrict extension to log-only mode
  }),
  ReactiveFormsModule,
  EffectsModule.forRoot([
    CommonEffects,
    ChangePasswordEffects,
    TakeMeWithYouEffects
  ]),
  NgIdleModule.forRoot()
];

GetTenantModule.getModule(myImports);

@NgModule({
  declarations: [
    DashboardComponent,
    TransactionsComponent,
    PersonalDetailsComponent,
    LoginErrorPageComponent,
    AppComponent,
    ChangePasswordComponent,
    PersonalEditComponent,
    BeneficiaryEditComponent,
    BeneficiaryViewComponent,
    TakeMeWithYouViewComponent,
    TransactionListViewComponent,
    QuestionnaireViewComponent,
    InsuranceViewComponent,
    SwitchInvestmentsViewComponent,
    RollInViewComponent,
    ContributionsViewComponent,
    PersonalContributionViewComponent,
    MakeContributionViewComponent,
    AssetsViewComponent,
    UnderlyingInvestmentViewComponent,
    UnitPricesComponent,
    MemberStatementsViewComponent,
    OtherDocumentsViewComponent,
    CalculatorViewComponent,
    ElevatedAccessComponent,
    PensionViewComponent,
    InvestmentEditViewComponent,
    AccessYourSuperViewComponent,
    MaintenancePageComponent,
    ErrorPageComponent,
    AccessYourSuperCreateComponent,
    AccessYourSuperReviewSubmittedComponent,
    NoticeOfIntentToClaimTaxDeductionViewComponent
  ],
  entryComponents: [
  ],
  imports: myImports,
  providers: [
    FeatureToggleCheckService,
    CurrencyPipe,
    DatePipe,
    MessagePipe,
    MembersService,
    NotAuthGuardService,
    EmploymentService,
    PensionGuardService,
    MsalCheckService,
    AccumGuardService,
    ShowQuestionnaireGuardService,
    FeatureFlagGuardService,
    AccountDataGuardService,
    AccountFeatureToggleGuardService,
    MemberFeatureToggleGuardService,
    BlackoutGuardService,
    ErrorPageGuardService,
    FeatureToggleService,
    AccountsService,
    HelperService,
    RolesService,
    PermissionGuardService,
    InsuranceService,
    BeneficiaryService,
    BPAYDetailsService,
    RollInService,
    TransactionService,
    TakeMeWithYouService,
    InvestmentService,
    MemberCorrespondenceService,
    UnderlyingInvestmentService,
    WebUserService,
    CostOfCoverService,
    MemberNoteTypeService,
    HistoricalDataService,
    SwitchInvestmentsService,
    InsuranceQuestionnaireService,
    MemberPensionService,
    UnitPriceService,
    DocumentsService,
    PersonalContributionService,
    NotificationService,
    CommomService,
    AccessYourSuperService,
    ChatService,
    NoticeOfIntentTaxDeductionService,
    [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpCustomInterceptor,
        multi: true
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: MsalInterceptor,
        multi: true
      }
    ],
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaApi }
  ],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent] //, MsalRedirectComponent]
})
export class AppModule {
  constructor(private injector: Injector) {
    AppInjector = this.injector;
  }
}

