<ngContainer *ngIf="formControl">
    <table class="table mr-2 access-your-super-drawdown">
        <thead>
            <tr>
                <th scope="col" width="20%">Option</th>
                <th scope="col" width="20%">Current ($)</th>
                <th scope="col" width="20%">Calculated amount</th>
                <th scope="col" width="40%" *ngIf="screenWidth > 575">Drawdown (% of Benefit amount)</th>
            </tr>
        </thead>
        <tbody>
            <ng-container
                *ngFor="let item of formControl?.controls.investmentOptionDrawdown.controls; let i = index; trackBy: trackByFn">
                <tr>
                    <td>{{item.value.option}}</td>
                    <td>{{item.value.amount | currency}}</td>
                    <td class="font-weight-450"
                        [ngClass]="{'font-green':((formControl.value.partialAmountSpecified * (item.value.percentage/100)) | convertNan) <= item.value.amount, 'font-red': ((formControl.value.partialAmountSpecified * (item.value.percentage/100)) | convertNan) > item.value.amount }">
                        {{formControl.value.partialAmountSpecified * (item.value.percentage/100) | convertNan |
                        currency}}</td>
                    <td *ngIf="screenWidth > 575">
                        <app-entry-number title="" [control]="item.controls.percentage" [decimalplaces]="2"
                            [showincremental]="false">
                        </app-entry-number>
                    </td>
                </tr>
                <tr *ngIf="screenWidth < 576">
                    <td class="font-weight-450 remove-border">
                        Drawdown (% of Benefit amount)
                    </td>
                    <td colspan="2" class="remove-border">
                        <app-entry-number title="" [control]="item.controls.percentage" [decimalplaces]="2"
                            [showincremental]="false">
                        </app-entry-number>
                    </td>
                </tr>
            </ng-container>
            <tr>
                <td colspan="4">
                    <div class="d-flex align-content-center font-italic">
                        {{drawdownDisclaimer}}
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="4">
                    <div class="d-flex justify-content-end">
                        <div *ngIf="formControl.value.investmentOptionDrawdown.length > 0"
                            class="mb-0 access-your-super-drawdown alert "
                            [ngClass]="{'alert-success': total === 100, 'alert-danger': total !== 100}">
                            Total: {{total | convertNan }}% of 100%
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</ngContainer>