<div id="notice-of-intent-form">

    <!-- form components -->
    <div *ngIf="form$|async; let form;">
        <ng-container *ngIf="!(submitted$|async)">

            <h3 class="mb-2">Personal details</h3>
            <!-- personal details -->
            <div class="section">
                <app-member-view [hideMemberNumber]="true" [tfnTooltip]="tfnTooltipText" [hideTfn]="false"
                    [hideDob]="false" [hideOccupation]="true" [greenIdValidation]="true"
                    [hideCommunicationPreference]="true"></app-member-view>

                <div class="d-flex justify-content-end">
                    <app-button [ngClass]="{'mr-1': screenWidth>767, 'mt-1 flex-fill': screenWidth<768}"
                        class="{{'BTN.notice-of-intent.update-details.class'|translate}}" title="Update details"
                        icon="{{'BTN.notice-of-intent.update-details.icon' | translate}}"
                        color="{{'BTN.notice-of-intent.update-details.color' | translate}}"
                        (onClick)="updatePersonalDetails()">
                    </app-button>
                </div>
            </div>

            <!-- form questions -->
            <h3 class="mb-2">Contribution details</h3>
            <form [ngrxFormState]="form" class="ml-3">
                <div class="section">
                    <div class="d-flex flex-row description mt-4">
                        <div [ngClass]="{'w-50': screenWidth>991, 'w-100': screenWidth<992}">
                            <app-entry-radio [title]="model.formWording.deductionType"
                                [tooltip]="model.formWording.deductionTypeTooltip"
                                [customList]="taxDeductionClaimTypes$"
                                [control]="form.controls.claimType"></app-entry-radio>
                        </div>
                    </div>
                    <div class="d-flex flex-row description mt-4">
                        <div [ngClass]="{'w-50': screenWidth>991, 'w-100': screenWidth<992}">
                            <app-entry-dropdown class="w-100" [title]="model.formWording.financialYear"
                                [tooltip]="model.formWording.financialYearTooltip"
                                [customList]="financialYearDropdownOptions$" [control]="form.controls.financialYear">
                            </app-entry-dropdown>
                        </div>
                    </div>
                    <div class="d-flex flex-row description mt-2">
                        <div [ngClass]="{'w-50': screenWidth>991, 'w-100': screenWidth<992}">
                            <app-readonly [title]="model.formWording.personalContribution"
                                [value]="form.value.personalContribution" class="w-50 flex-fill"
                                [tooltip]="model.formWording.personalContributionTooltip"></app-readonly>
                        </div>
                    </div>

                    <ng-container *ngIf="form.value.claimType === 1">
                        <div class="d-flex flex-row description mt-4 mb-3">
                            <div [ngClass]="{'w-50': screenWidth>991, 'w-100': screenWidth<992}">
                                <app-entry-number [title]="model.formWording.variantTypeOriginalClaimAmount"
                                    [showincremental]="false"
                                    [control]="form.controls.originalClaimAmount"></app-entry-number>
                            </div>
                        </div>
                    </ng-container>

                    <div class="d-flex flex-row description"
                        [ngClass]="{'mt-4': form.value.claimType === 0, 'mt-2': form.value.claimType === 1}">
                        <div [ngClass]="{'w-50': screenWidth>991, 'w-100': screenWidth<992}">
                            <app-entry-number [title]="form.value.claimType === 0 ?
                            model.formWording.newTypeClaimAmount : model.formWording.variantTypeClaimAmount"
                                [showincremental]="false"
                                [tooltip]="form.value.claimType === 0 ? model.formWording.newTypeClaimAmountTooltip : model.formWording.variantTypeClaimAmountTooltip"
                                [control]="form.controls.claimAmount"></app-entry-number>
                        </div>
                    </div>

                    <div class="d-flex flex-row description mt-4">
                        <div class="w-100">
                            <app-entry-radio [title]="model.formWording.withdrawalRequested" list="yesNo"
                                [tooltip]="model.formWording.withdrawalRequestedTooltip"
                                [control]="form.controls.hasWithdrawalPending"></app-entry-radio>
                        </div>
                    </div>
                </div>

                <!-- submission disclaimer -->
                <ng-container>
                    <h3 class="mb-2">Disclaimer</h3>
                    <div [innerHtml]="(disclaimer$|async)">
                    </div>
                    <app-checkbox title="I have read and understood the above disclaimer."
                        [control]="form.controls.disclaimer" class="mb-2"></app-checkbox>
                </ng-container>
            </form>

            <!-- action buttons -->
            <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
                class="d-flex mt-3">
                <app-button [ngClass]="{'mr-1': screenWidth>767, 'mt-1': screenWidth<768}"
                    class="{{'BTN.notice-of-intent.back.class' | translate}}" title="Cancel"
                    icon="{{'BTN.notice-of-intent.cancel.icon' | translate}}"
                    color="{{'BTN.notice-of-intent.cancel.color' | translate}}" (onClick)="cancel()"></app-button>
                <app-button [ngClass]="{'ml-1': screenWidth>767, 'mt-1': screenWidth<768}" class=""
                    *ngIf="!(elevatedAccess$ | async)" title="Submit"
                    icon="{{'BTN.notice-of-intent.continue.icon' | translate}}"
                    color="{{'BTN.notice-of-intent.continue.color' | translate}}" [isDisabled]="form.isInvalid"
                    (onClick)="submit(form.value)"></app-button>
            </div>
        </ng-container>

        <ng-container *ngIf="(submitted$|async)">
            <div
                [innerHtml]="form.value.claimType === 0 ? model.formWording.newTypeSubmissionMessage : model.formWording.variantTypeSubmissionMessage">
            </div>
        </ng-container>
    </div>


</div>