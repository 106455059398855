import { getMemberPensionView_beneficiaryList, getMemberPensionView_Model } from './selectors';
import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { RequestAction, RequestBeneficiariesAction, ResetDataAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { AppState } from '../../../../store/app.states';
import { MemberPortalSharedState } from '../../store/shared.states';
import { commomState_LoggedinInfo } from 'src/app/store/common/common.selectors';
import { Helper } from '@ifaa-components/ui-components';
import { memberAccountDropdown_SelectedAccount } from '../member-account-dropdown/selectors';
import { FeatureToggleName } from 'src/app/model/feature-toggle-name.model';

@Component({
  selector: 'app-member-pension-view',
  templateUrl: './member-pension-view.component.html',
  styleUrls: ['./member-pension-view.component.scss']
})
export class MemberPensionViewComponent extends ComponentBase implements OnInit, OnDestroy {

  data$ = this.store.pipe(select(getMemberPensionView_Model));
  beneficiaryList$ = this.store.pipe(select(getMemberPensionView_beneficiaryList));
  @Input() itemclass: string = 'w-50';
  loggedIn$ = this.appstore.pipe(select(commomState_LoggedinInfo));

  helper = new Helper();

  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));

  changePensionScheduleFeatureToggle = FeatureToggleName.member.account.changePensionSchedule.view;
  editBankDetailsFeatureToggle = FeatureToggleName.member.account.editBankDetails.view;
  centrelinkScheduleFeatureToggle = FeatureToggleName.member.account.centrelinkSchedule.view;

  constructor(public store: Store<MemberPortalSharedState>,
    public appstore: Store<AppState>) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.dispatch(this.store, ResetDataAction());

    this.sub = this.selectedAccount$.subscribe(x => {
      if (x) {
        this.dispatch(this.store, RequestAction({ accountId: x.accountId }));
        this.dispatch(this.store, RequestBeneficiariesAction({ accountId: x.accountId }));
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

}
