import { createFeatureSelector, createSelector } from "@ngrx/store";
import { MemberPortalSharedState } from "../../store/shared.states";
import { featureKey } from '../../store/shared.reducers';
import { IAccessYourSuperReviewComponentState } from "./state";

export const memberPortalState = createFeatureSelector<MemberPortalSharedState>(featureKey);

export const getAccessYourSuperReviewState = createSelector(memberPortalState, (state) => state.accessYourSuperReviewComponentState);

export const accessYourSuperReview_Form = createSelector(
    getAccessYourSuperReviewState,
    (state: IAccessYourSuperReviewComponentState) => state.form
);

export const accessYourSuperReview_ApplicationSubmitted = createSelector(
    getAccessYourSuperReviewState,
    (state: IAccessYourSuperReviewComponentState) => state.applicationSubmitted
)

export const accessYourSuperReview_CustomData = createSelector(
    getAccessYourSuperReviewState,
    (state: IAccessYourSuperReviewComponentState) => state.customData
)