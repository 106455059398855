<div *ngIf="model">
    <div *ngIf="!applicationSubmitted">
        <h2 class="mb-4">Review your application</h2>
        <div *ngIf="form$ | async; let form" class="ml-3">

            <app-access-your-super-review-display [data]="model" [customSection]="customData"></app-access-your-super-review-display>

            <form [ngrxFormState]="form">
                <div class="pt-3 top-border text-justify">
                    <h3 class="mb-2">Disclaimer</h3>
                    <div [innerHtml]="model.conditionData.conditionDisclaimer"></div>
                </div>
                <div class="d-flex flex-row mt-3">
                    <div class="mt-1">
                        <app-checkbox title="I have read and understood the above disclaimer."
                            [control]="form.controls.disclaimer"></app-checkbox>
                    </div>
                </div>
            </form>

            <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
                class="d-flex flex-wrap mt-4">
                <div [ngClass]="{'mt-1': screenWidth<768}">
                    <app-button class="flex-fill" title="Exit"
                        icon="{{'BTN.access-your-super.cancel.icon' | translate}}"
                        color="{{'BTN.access-your-super.cancel.color' | translate}}"
                        (onClick)="exitForm()"></app-button>
                </div>
                <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
                    class="d-flex">
                    <app-button [ngClass]="{'mr-1': screenWidth>767, 'mt-1': screenWidth<768}" class="flex-fill {{'BTN.access-your-super.back.class' | translate}}"
                        title="Back" icon="{{'BTN.access-your-super.back.icon' | translate}}"
                        color="{{'BTN.access-your-super.back.color' | translate}}" (onClick)="goBack()"></app-button>
                    <app-button [ngClass]="{'ml-1': screenWidth>767, 'mt-1': screenWidth<768}" class="flex-fill"
                      *ngIf="!(elevatedAccess$ | async)"
                        title="Submit application" icon="{{'BTN.access-your-super.continue.icon' | translate}}"
                        color="{{'BTN.access-your-super.continue.color' | translate}}" [isDisabled]="form.isInvalid"
                        (onClick)="submitApplication()"></app-button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="applicationSubmitted">
        <h2 class="mb-4">Your benefit access request has been successfully submitted</h2>
        <p class="mb-0 ml-3">Your benefit access request will be reviewed, and you will be notified accordingly after
            processing is completed or if we need any further information.</p>
        <div class="d-flex justify-content-end">
            <app-button class="mt-3 ml-1" title="Finish" icon="{{'BTN.access-your-super.start.icon' | translate}}"
                color="{{'BTN.access-your-super.start.color' | translate}}" routerLink="/access-your-super">>
            </app-button>
        </div>
    </div>
</div>
