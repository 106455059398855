<ng-container *ngIf="(currentStep$|async); let currentStep">
    <!-- step 0 - landing page -->
    <div *ngIf="currentStep === 1">
        <p>You may be eligible to claim or vary a tax deduction for your personal contributions made to your superannuation
            account from your after-tax income. We need to accept your Notice of Intent to Claim a tax deduction request
            and you need to receive an acknowledgement of this from us before you can claim a tax deduction in your
            income tax returns.</p>
        <p>Once your request has been acknowledged and processed, the applicable transactions will appear under <a
                routerLink="/transaction-listing">Transactions</a> for the relevant financial year.</p>

        <h3 class="mt-4">Requests submitted online</h3>
        <table class="table {{'VIEWS.Global.table-default-style' | translate}}">
            <thead>
                <tr>
                    <th>Financial Year</th>
                    <th>Personal Contribution</th>
                    <th>Claim Amount</th>
                    <th>Submitted Date</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let item of applications">
                    <tr>
                        <td>{{item.financialYear}}</td>
                        <td>{{item.personalContributionAmount}}</td>
                        <td>{{item.claimAmount}}</td>
                        <td>{{item.submittedDate}}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>

        <div class="d-flex justify-content-end mt-4">
            <app-button class="mr-1 continue-btn" title="Lodge a notice of intent"
                icon="{{'BTN.notice-of-intent.continue.icon' | translate}}"
                color="{{'BTN.notice-of-intent.continue.color' | translate}}"
                (onClick)="startApplication()"></app-button>
        </div>
    </div>

    <ng-container *ngIf="formComponentData$|async; let formComponentData">
        <!-- step -1 - show error page -->
        <div *ngIf="currentStep === -1">
            <p>At this time, you don't seem to be eligible to claim or vary a tax deduction since <span [innerHtml]="formComponentData.errorMessage"></span>. Please contact us if you would like this to be reviewed or you need
                any further details.</p>
        </div>

        <!-- step 2 - start disclaimer -->
        <ng-container *ngIf="form$|async; let form">
            <div *ngIf="currentStep === 2">
                <div [innerHtml]="formComponentData.formWording.startDisclaimer"></div>
                <div [innerHtml]="formComponentData.formWording.ageDisclaimer"></div>
                <p>For further information to claim or vary a deduction for personal contributions, please click <a target="_blank"
                        href="https://www.ato.gov.au/forms-and-instructions/superannuation-personal-contributions-notice-of-intent-to-claim-or-vary-a-deduction">here</a>.
                </p>
                <app-checkbox title="I have read and understood the above eligibility criteria."
                    [control]="form.controls.disclaimer" class="mb-2"></app-checkbox>
                <p>Information provided is of a general nature only and we have not taken your personal financial
                    objectives, situation or needs into account. Please consult a professional tax or financial adviser,
                    if needed.</p>
                <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
                    class="d-flex mt-3">
                    <app-button [ngClass]="{'mr-1': screenWidth>767, 'mt-1': screenWidth<768}"
                        class="{{'BTN.notice-of-intent.back.class' | translate}}" title="Cancel"
                        icon="{{'BTN.notice-of-intent.cancel.icon' | translate}}"
                        color="{{'BTN.notice-of-intent.cancel.color' | translate}}" (onClick)="cancel()"></app-button>
                    <app-button [ngClass]="{'ml-1': screenWidth>767, 'mt-1': screenWidth<768}" class=""
                        *ngIf="!(elevatedAccess$ | async)" title="Continue"
                        icon="{{'BTN.notice-of-intent.continue.icon' | translate}}"
                        color="{{'BTN.notice-of-intent.continue.color' | translate}}" [isDisabled]="form.isInvalid"
                        (onClick)="continue()"></app-button>
                </div>
            </div>
        </ng-container>

        <!-- step 3 - form and submission disclaimer -->
        <div *ngIf="currentStep === 3">
            <app-notice-of-intent-tax-deduction-form [data]="formComponentData"
                [account]="accountId"></app-notice-of-intent-tax-deduction-form>
        </div>

    </ng-container>
</ng-container>