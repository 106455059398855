<div id="investment-simple-view-container" >
    <div class="table-container">
      <div class="d-flex flex-fill flex-column table-view">
        <div>
          <h4 class="d-flex align-items-center ">
            <div class="flex-fill d-flex" *ngIf="!isPensionAccount">
              <span class="simple-view-description description" *ngIf="!((currentInfo$ | async)?.hasPendingCurrentOptions)">Your current balance and future contributions or rollovers received into the fund will be invested in</span>
              <span *ngIf="(currentInfo$ | async)?.hasPendingCurrentOptions">Your current balance is invested in</span>
              <mat-icon class="ml-2 help-icon" *ngIf="(currentInfo$ | async); let data"
                matTooltip="Your current balance is as at {{getAsAtBalanceDate(data) | date:'dd MMM yyyy'}} and has been calculated using the latest unit price.">
                help_outline</mat-icon>
            </div>
            <div *ngIf="isPensionAccount && filterCurrent((currentInfo$|async)?.current)?.length > 0">
                Your entire balance is currently invested in {{filterCurrent((currentInfo$|async)?.current)[0].option}} and this is where your future pension payments will be paid from
            </div>

            <div *ngIf="(currentInfo$ | async)?.hasPendingCurrentOptions"
              class=" badge badge-info float-right d-flex align-items-center click"
              matTooltip="Your current investment switch is in progress, which restricts changes to the allocation of your existing balance until it is complete.">
              <mat-icon class="animate__delay-1s animate__animated animate__tada">hourglass_top</mat-icon>
              Pending Changes
            </div>
          </h4>
        </div>
        <table class="table mr-2 {{'VIEWS.Global.table-default-style' | translate}}">
          <thead>
            <tr>
              <th scope="col">Option</th>
              <th scope="col">%</th>
              <th scope="col">$</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of filterCurrent((currentInfo$|async)?.current); index as i">
              <tr>
                <td>{{item.option}}</td>
                <td>{{item.percentage}}%</td>
                <td>{{item.amount | currency}}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>

    <div class="alert alert-info m-3" *ngIf="(currentInfo$ | async)?.hasPendingCurrentOptions && !isDashboardView">
        You have a pending switch request which will be processed as soon as the unit price for the requested date has been loaded.
    </div>

    <div class="row float-right" *ngIf="!isPensionAccount && !isDashboardView">
      <app-button loading title="Manage Investments" [isDisabled] ="(currentInfo$ | async)?.hasPendingCurrentOptions" icon="{{'BTN.beneficiary-view.edit.icon' | translate}}" (click)="onEditItemClick(1, [0,1,3], false)"  *featuretoggle="updateInvestmentsFeatureToggle" >
          </app-button>
    </div>

</div>
