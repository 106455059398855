import { FormGroupState } from "ngrx-forms";
import { AccessYourSuperKiwiSaverDetailSectionModel, AccessYourSuperPaymentDetailSectionModel, AccessYourSuperSfhDetailSectionModel } from "src/app/model/access-your-super-custom-sections.model";

export interface IAccessYourSuperReviewComponentState {
    form:FormGroupState<AccessYourSuperReviewForm>;
    applicationSubmitted:boolean;
    customData:AccessYourSuperPaymentDetailSectionModel|AccessYourSuperKiwiSaverDetailSectionModel|AccessYourSuperSfhDetailSectionModel;
}

export class AccessYourSuperReviewForm{
    disclaimer:boolean = false;
}