<div id="smrt-no-insurance">
  <ng-container [ngSwitch]="fund">

    <div *ngSwitchCase="'FRES'">
      <p class="alert alert-danger">You currently do not hold insurance with {{(tenantConfig$ | async)?.name}}</p>

      <p class="" [innerHTML]="'VIEWS.Insurance.FRES.yourInsuranceCover_Description' | translate">
      </p>
      <p class="d-flex flex-column align-items-start">
        <a class="btn no-decoration w-100 d-flex align-items-center click no-text-decoration" target="_blank"
          href="{{item.url}}" *ngFor="let item of data?.fundDocuments">
          <mat-icon *ngIf="item.icon" class="mr-2 ml-2">{{item.icon}}</mat-icon>
          <label>{{item.title}}</label>
        </a>
      </p>

      <p [innerHTML]="'VIEWS.Insurance.FRES.yourInsuranceCover_YourInsuranceCover' | translate">
      </p>
    </div>
    <div *ngSwitchCase="'KPMG'">
      <p class="alert alert-danger">You currently do not hold insurance with {{(tenantConfig$ | async)?.name}}</p>

      <p class="" [innerHTML]="'VIEWS.Insurance.KPMG.yourInsuranceCover_Description' | translate">
      </p>
      <p class="d-flex flex-column align-items-start">
        <a class="btn no-decoration w-100 d-flex align-items-center click no-text-decoration" target="_blank"
          href="{{item.url}}" *ngFor="let item of data?.fundDocuments">
          <mat-icon *ngIf="item.icon" class="mr-2 ml-2">{{item.icon}}</mat-icon>
          <label>{{item.title}}</label>
        </a>
      </p>

      <p [innerHTML]="'VIEWS.Insurance.KPMG.yourInsuranceCover_YourInsuranceCover' | translate">
      </p>


    </div>



    <div *ngSwitchDefault>
      <div *ngIf="(selectedAccount$ | async)?.previousAccount">

        <h4>Update on smartMonday insurance cover</h4>   
        <ul>
          <li>The insurance you held as at 1 April is still valid.</li>
          <li>We are still working on transferring your insurance details to our new member portal.</li>
          <li>If you need to check your insurance cover, please refer to your 2023 annual member statement.</li>
        </ul>
      </div>

      <div *ngIf="!(selectedAccount$ | async)?.previousAccount">

        <p class="alert alert-danger">You currently do not hold insurance with {{(tenantConfig$ | async)?.name}}</p>
        <p class="" [innerHTML]="'yourInsuranceCover_Description'|message|async">
        </p>
        <p class="d-flex flex-column align-items-start">
          <a class="btn no-decoration w-100 d-flex align-items-center click no-text-decoration" target="_blank"
            href="{{item.url}}" *ngFor="let item of data?.fundDocuments">
            <mat-icon *ngIf="item.icon" class="mr-2 ml-2">{{item.icon}}</mat-icon>
            <label>{{item.title}}</label>
          </a>
        </p>
  
        <p [innerHTML]="('yourInsuranceCover_YourInsuranceCover' | message | async)">
        </p>
      </div>

    </div>
  </ng-container>

</div>