import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/app.states';
import { ComponentBase } from '../../views/component-base';
import { OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-elevated-access',
  templateUrl: './elevated-access.component.html',
  styleUrls: ['./elevated-access.component.scss']
})
export class ElevatedAccessComponent extends ComponentBase implements OnInit, OnDestroy {

  constructor(public store: Store<AppState>, public router: Router) {
    super();
    sessionStorage.setItem('login-type', 'Elevated');
    router.navigate(['dashboard']);

    // check if we are in iframe - if we aren't, redirect to dashboard
    if (window.self === window.top)
      window.location.href = '/dashboard'
  }

  ngOnInit() {
    super.ngOnInitBase();
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }


}
