import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { ComponentBase } from "src/app/views/component-base";
import { SetCurrentStepAction } from "../access-your-super-form/actions";
import { AppState } from "src/app/store/app.states";
import { Store, select } from "@ngrx/store";
import { Router } from "@angular/router";
import { AccessYourSuperApplicationDataModel, AccessYourSuperDocumentOverviewModel } from "src/app/model/access-your-super-form.models";
import { Helper } from "@ifaa-components/ui-components";
import { accessYourSuperReview_ApplicationSubmitted, accessYourSuperReview_CustomData, accessYourSuperReview_Form } from "./selector";
import { GetCustomDataAction, GetKiwiSaverDataAction, GetSfhDataAction, ResetComponentStateAction, SubmitApplicationAction } from "./actions";
import { AccessYourSuperKiwiSaverDetailSectionModel, AccessYourSuperPaymentDetailSectionModel, AccessYourSuperSfhDetailSectionModel } from "src/app/model/access-your-super-custom-sections.model";
import { AccessYourSuperHelper } from "src/app/helper/accessyourSuperHelper";
import { AccessYourSuperApplicationTypeEnum } from "src/app/enums/access-your-super-application-type.enum";
import { commomState_ElevatedAccess } from "src/app/store/common/common.selectors";

@Component({
  selector: 'access-your-super-review',
  templateUrl: './access-your-super-review.component.html',
  styleUrls: ['./access-your-super-review.component.scss']
})

export class AccessYourSuperReviewComponent extends ComponentBase implements OnInit, OnDestroy {

  helper = new Helper();

  @Output() onSubmissionSuccesful: EventEmitter<any> = new EventEmitter();

  accessYourSuperHelper = new AccessYourSuperHelper();
  @Input() set data(value: AccessYourSuperApplicationDataModel) {
    var clone = this.helper.clone(value);
    this.model = clone;
  }
  @Input() set backStep(value: number) {
    this.previousStep = value;
  }
  @Input() set applicationId(value: number) {
    this.id = value;
  }
  @Input() set account(value: number) {
    this.accountId = value;
  }

  form$ = this.store.pipe(select(accessYourSuperReview_Form));
  customData$ = this.store.pipe(select(accessYourSuperReview_CustomData));
  applicationSubmitted$ = this.store.pipe(select(accessYourSuperReview_ApplicationSubmitted));
  elevatedAccess$ = this.store.pipe(select(commomState_ElevatedAccess));
  elevatedAccess = false;

  model: AccessYourSuperApplicationDataModel = null;
  previousStep: number = null;
  id: number = null;
  accountId: number = null;
  customData: AccessYourSuperPaymentDetailSectionModel | AccessYourSuperKiwiSaverDetailSectionModel | AccessYourSuperSfhDetailSectionModel = null;
  applicationSubmitted: boolean = null;

  constructor(
    public store: Store<AppState>,
    private router: Router) {
    super();
  }

  async ngOnInit() {
    super.ngOnInitBase();
    this.sub = this.elevatedAccess$.subscribe(x => {
      this.elevatedAccess = x;
    })
    // Get the submitted custom section data to display on review page
    this.sub = this.customData$.subscribe(x => {
      if (!x) {
        switch (this.model.conditionData.applicationTypeId) {
          case AccessYourSuperApplicationTypeEnum.SevereFinancialHardship: {
            this.dispatch(this.store, GetSfhDataAction({ accountId: this.accountId, applicationId: this.id }))
            break;
          }
          case AccessYourSuperApplicationTypeEnum.TransferToKiwiSaver: {
            this.dispatch(this.store, GetKiwiSaverDataAction({ accountId: this.accountId, applicationId: this.id }))
            break;
          }
          default: {
            this.dispatch(this.store, GetCustomDataAction({ accountId: this.accountId, applicationId: this.id, applicationTypeId: this.model.conditionData.applicationTypeId }));
            break;
          }
        }
      }
      if (x) {
        this.customData = x;
      }
    })

    this.sub = this.applicationSubmitted$.subscribe(x => {
      if (x) {
        this.applicationSubmitted = x;
        this.onSubmissionSuccesful.emit(x);
      }
    })

  }

  showSection(sectionIndex: number) {
    return this.model.conditionData.requiredSections.includes(sectionIndex);
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
    this.dispatch(this.store, ResetComponentStateAction())
  }

  exitForm() {
    this.router.navigate(['/access-your-super']);
  }

  async submitApplication() {
    if (this.elevatedAccess) return;
    this.dispatch(this.store, SubmitApplicationAction({ accountId: this.accountId, applicationId: this.id }))
  }

  // The back button on the last employer details will always go to available balance component
  goBack() {
    this.dispatch(this.store, SetCurrentStepAction({ nextStep: this.previousStep }))
  }
}
