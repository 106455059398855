import { AccountModel } from './../../../../model/account.model';
import { Subscription, combineLatest } from 'rxjs';
import { SetMenuAction } from './../../../../store/common/common.actions';
import { mainLayout_Animated, mainLayout_Expanded } from './selectors';
import { MenuModel } from './../../../../model/menu.model';
import { AppState } from 'src/app/store/app.states';
import { Component, HostListener, Inject, TemplateRef, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ComponentBase } from 'src/app/views/component-base';
import { commomState_IsLoading, commomState_LoggedinInfo, commomState_MenuList, commonState_SystemConfig_AccountFeatureToggles } from 'src/app/store/common/common.selectors';
import { MainExpandedRequestAction, MainSetAnimatedPageAction } from './actions';
import { SMRTState } from '../../store/smrt.states';
import { MatDrawer } from '@angular/material/sidenav';
import { insuranceQuestionnaire_ShowQuestionnaire } from 'src/app/modules/shared/components/insurance-questionnaire/selectors';
import { Title } from '@angular/platform-browser';
import { memberViewOverview_Model } from 'src/app/modules/shared/components/member-view-overview/selectors';
// import { RequestAction } from 'src/app/modules/shared/components/member-view/actions';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { filter } from 'rxjs/operators';
import { memberAccountDropdown_SelectedAccount } from 'src/app/modules/shared/components/member-account-dropdown/selectors';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { AccountFeatureToggle } from 'src/app/model/account-feature-toggles.model';
import { FeatureToggleValue } from 'src/app/model/feature-toggle-value.model';
import { FeatureToggleName } from 'src/app/model/feature-toggle-name.model';
import { FeatureToggleHelper } from 'src/app/helper/featureToggleHelper';


@Component({
  selector: 'login-master',
  templateUrl: './main.master.html',
  styleUrls: ['./main.master.scss']
})
export class MainMasterComponent extends ComponentBase {

  expanded: MenuModel[] = [];
  animated$ = this.store.pipe(select(mainLayout_Animated));
  expanded$ = this.smrtstore.pipe(select(mainLayout_Expanded));
  menuList$ = this.store.pipe(select(commomState_MenuList));
  loggedInUser$ = this.store.pipe(select(commomState_LoggedinInfo));
  isLoading$ = this.store.pipe(select(commomState_IsLoading));
  isQuestionnarieVisible$ = this.store.pipe(select(insuranceQuestionnaire_ShowQuestionnaire));
  user$ = this.store.pipe(select(memberViewOverview_Model));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  accountFeatureToggles$ = this.store.pipe(select(commonState_SystemConfig_AccountFeatureToggles));

  accountFeatureToggles: AccountFeatureToggle[] = null;
  featureToggleValues: any = null;
  featureToggleHelper = new FeatureToggleHelper();

  menuMode = "side";
  menuOpened = true;
  showMenuIconTop = true;
  selectedAccount: AccountModel = null;
  @ViewChild('templateBottomSheet') TemplateBottomSheet: TemplateRef<any>;
  menu = [
    { id: '1', name: 'Overview', link: '/dashboard', icon: 'house', children: [] } as MenuModel,
    {
      id: '3', name: 'Personal Details', icon: 'account_box', children: [
        { id: '3-3', name: 'Change your password', link: '/change-password', icon: 'lock', children: [], featureFlag: FeatureToggleName.member.changePassword.view } as MenuModel,
        { id: '3-1', name: 'Update your details', link: '/personal-details', icon: 'create', children: [], featureFlag: FeatureToggleName.member.personalDetails.view } as MenuModel,
        // { id: '3-2', name: 'Employment details', link: '/', icon: 'business', children: [] } as MenuModel,
        { id: '3-4', name: 'Beneficiaries', link: '/beneficiaries', icon: 'supervisor_account', children: [], featureFlag: FeatureToggleName.member.account.beneficiaries.view } as MenuModel,
        { id: '3-5', name: 'Adviser', link: '/', icon: 'psychology', children: [], featureFlag: FeatureToggleName.member.account.advisors.view } as MenuModel,
        { id: '3-6', name: 'Take me with you', link: '/take-me-with-you', icon: 'tour', children: [], featureFlag: FeatureToggleName.member.account.takeMeWithYou.view } as MenuModel,
      ]
    } as MenuModel,
    {
      id: '2', name: 'Account Details', icon: 'manage_accounts', children: [
        { id: '2-1', name: 'Transactions', link: '/transaction-listing', icon: 'list', children: [], featureFlag: FeatureToggleName.member.account.transactions.view } as MenuModel,
        { id: '2-2', name: 'Manage Investments', link: '/investment', icon: 'currency_exchange', children: [], featureFlag: FeatureToggleName.member.account.manageInvestments.update } as MenuModel,
        { id: '2-3', name: 'Unit price listing', link: '/unit-prices', icon: 'paid', children: [], featureFlag: FeatureToggleName.member.unitPriceListing.view } as MenuModel,
        { id: '2-4', name: 'Underlying investment', link: '/underlying-investment', icon: 'request_quote', children: [], featureFlag: FeatureToggleName.member.account.underlyingInvestmentSummary.view } as MenuModel,
        { id: '2-5', name: 'Insurance details', link: '/insurance', icon: 'security', children: [], featureFlag: FeatureToggleName.member.account.insuranceDetails.view } as MenuModel,
      ]
    } as MenuModel,
    {
      id: '15', name: 'Contributions', icon: 'paid', children: [
        { id: '15-1', name: 'Make a contribution', link: '/make-a-contribution', icon: 'note_add', children: [], featureFlag: FeatureToggleName.member.account.makeAContribution.view } as MenuModel,
        { id: '15-2', name: 'Contribution cap limits', link: '/contributions', icon: 'settings_ethernet', children: [], featureFlag: FeatureToggleName.member.account.contributionCapLimits.view } as MenuModel,
        { id: '15-3', name: 'Consolidate your super', link: '/rollin', icon: 'merge_type', children: [], featureFlag: FeatureToggleName.member.account.rollIn.view } as MenuModel,
        { id: '15-4', name: 'Notice of intent to claim a tax deduction', link: '/claim-a-tax-deduction', icon: 'price_change', children: [], featureFlag: FeatureToggleName.member.account.intentToClaimTaxDeduction.view } as MenuModel,
        { id: '15-6', name: 'SuperMatch', link: '/rollin', icon: 'join_inner', children: [], featureFlag: FeatureToggleName.member.account.superMatch.view } as MenuModel,
      ]
    } as MenuModel,
    {
      id: '54', name: 'Pension', icon: 'savings', children: [
        { id: '54-1', name: 'Pension schedule', link: '/pension-details', icon: 'event', children: [], featureFlag: FeatureToggleName.member.account.pensionDetails.view } as MenuModel,
        { id: '54-2', name: 'Centrelink schedule', link: '/', icon: 'event', children: [], featureFlag: FeatureToggleName.member.account.centrelinkSchedule.view } as MenuModel,
      ]
    } as MenuModel,
    {
      id: '16', name: 'Access Your Super', link: '/access-your-super', icon: 'price_change', children: [], featureFlag: FeatureToggleName.member.account.accessYourSuper.view
    } as MenuModel,
    {
      id: '7', name: 'Documents', icon: 'folder', children: [
        { id: '7-1', name: 'View statements', link: '/member-statements', icon: 'text_snippet', children: [], featureFlag: FeatureToggleName.member.account.viewStatements.view } as MenuModel,
        { id: '7-2', name: 'Other documents', link: '/other-documents', icon: 'article', children: [], featureFlag: FeatureToggleName.member.account.otherDocuments.view } as MenuModel,
      ]
    } as MenuModel,
  ]

  @ViewChild("drawer") drawer: MatDrawer;

  constructor(public store: Store<AppState>,
    public smrtstore: Store<SMRTState>,
    public dialog: MatDialog,
    private titleService: Title,
    private bottomSheet: MatBottomSheet,
    private router: Router,
    private msalInstance: MsalService) {
    super();
    this.titleService.setTitle("smartMonday Member Portal");
  }

  ngOnInit() {
    super.ngOnInitBase();
    // this.store.dispatch(RequestAction());

    this.featureToggleValues = {
      available: FeatureToggleValue.available,
      hidden: FeatureToggleValue.hidden,
      disabled: FeatureToggleValue.disabled
    };

    this.sub = this.selectedAccount$.subscribe(x => {
      if (x)
        this.selectedAccount = x;
    });

    this.screenWidthChange(this.store, (width: number) => {
      this.menuMode = "side";
      this.menuOpened = true;

      if (width < 1300) {
        this.menuMode = "over";
        this.menuOpened = false;
      }
      this.sub = this.router.events
        .pipe(filter(event => event instanceof RoutesRecognized))
        .subscribe((event) => {
          this.bottomSheet.dismiss(this.TemplateBottomSheet);
        });


    });
    setTimeout(() => {
      this.store.dispatch(MainSetAnimatedPageAction());

    }, 1000);


    this.sub = this.expanded$.subscribe(x => {
      this.expanded = x;
    });


    // Filter menu items based on insurance questionnaire and feature toggles, and then push to redux state
    combineLatest([this.selectedAccount$, this.accountFeatureToggles$, this.isQuestionnarieVisible$])
      .subscribe((([selectedAccount, featureToggles, isVisible]) => {
        if (selectedAccount && featureToggles && (isVisible === true || isVisible === false)) {

          //Filter questionnaire stuff first
          var newMenu = [...this.menu];
          if (!isVisible) {
            for (let i = 0; i < newMenu.length; i++) {
              newMenu[i] = { ...newMenu[i], children: newMenu[i].children?.filter(c => c.id != '6-2') };
            }
          }
          else {
            for (let i = 0; i < newMenu.length; i++) {
              newMenu[i] = { ...newMenu[i], children: newMenu[i].children?.filter(c => c.id != '6-1') };
            }
          }

          // Filter feature toggles
          var returnMenu = [];
          for (let i = 0; i < newMenu.length; i++) {
            // If the menu item has children we should check that at least 1 child item will be available/disabled
            // If there are 0 child items available/disabled, we dont show the top level menu item
            if (newMenu[i].children.length > 0) {
              var topLevelMenuItem = { ...newMenu[i], children: newMenu[i].children?.filter(c => this.featureToggleHelper.checkToggle(c.featureFlag, featureToggles, selectedAccount)) };

              if (topLevelMenuItem.children.length > 0) {
                returnMenu.push(topLevelMenuItem);
              }
            }
            else {
              // If there are 0 children, we just check the feature toggle on the top level item and add it if its not hidden (e.g. AYS)
              if (this.featureToggleHelper.checkToggle(newMenu[i].featureFlag, featureToggles, selectedAccount)) {
                returnMenu.push(newMenu[i]);
              }
            }
          }

          this.setMenu(returnMenu);
        }
      }));
  }
  bs: any = null;
  openMenu() {
    if (this.bs) {
      this.bs.dismiss();
      this.bs = null;
      return;
    }
    this.bs = this.bottomSheet.open(this.TemplateBottomSheet, {
      panelClass: 'menu-items'
    });
  }

  openedChange() {
    if (this.drawer) {
      this.menuOpened = this.drawer.opened;
    }
  }
  onSwipeToggle(event) {
    this.menuOpened = !this.menuOpened;
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onItemSelectedClick(item: MenuModel) {
    this.store.dispatch(MainExpandedRequestAction({ menuItem: item }))

    if (item.link?.startsWith('http')) {
      window.open(item.link, "_blank");
    }
    else {
      if (item.link)
        this.router.navigate([item.link]);

    }
  }

  isMenuExpanded(item: MenuModel) {
    return this.expanded.filter(x => x.id == item.id).length > 0;
  }

  onSignoutClick(): void {
    this.msalInstance.logoutRedirect(
      {      postLogoutRedirectUri: environment.b2cLogoutUrl  }
      );
  }


  setMenu(menu: MenuModel[]) {
    this.store.dispatch(SetMenuAction({ data: menu }))
  }

}
