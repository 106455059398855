import { KeyValueModel } from '@ifaa-components/ui-components';
import { FormGroupState } from 'ngrx-forms';
import { NoticeOfIntentTaxDeductionContributions } from 'src/app/model/notice-of-intent-tax-deduction.model';

export interface INoticeOfIntentTaxDeductionFormComponentState {
  form: FormGroupState<NoticeOfIntentTaxDeductionFormComponentForm>;
  submitted: boolean;
  taxDeductionClaimTypes: KeyValueModel[];
  financialYearDropdownOptions: KeyValueModel[];
  disclaimer:string;
}

export class NoticeOfIntentTaxDeductionFormComponentForm {
  claimType: number = 0;
  financialYear: number = 0;
  personalContribution: string = "$0.00";
  originalClaimAmount: number = 0;
  claimAmount:number = 0;
  hasWithdrawalPending: boolean = false;
  disclaimer: boolean = false;
  financialYearContributions: NoticeOfIntentTaxDeductionContributions = new NoticeOfIntentTaxDeductionContributions();
}