<div *ngIf="model">
    <h2 class="mb-3">{{title}}</h2>
    <div *ngIf="form$|async; let form">

        <div class="px-3 pb-2">
            <div class="mb-4">
                <div>
                    <h3 class="font-weight-bold mb-1">Proof of identification</h3>
                    <p>{{proofOfIdentificationText}}</p>
                    <p *ngIf="!form.value.greenIdVerified">Please click ‘Start verification' to verify your identity. It is important to note that to
                        complete your ID verification you will need your identification details readily available.</p>
                </div>
                <app-greenid [accountid]="accountId" (onVerified)="onVerified($event)"></app-greenid>
            </div>

            <form [ngrxFormState]="form" *ngIf="applicationInDraft || (!applicationInDraft && form.value.greenIdVerified)">
                <div *ngFor="let item of form?.controls.supportingDocuments.controls; let i = index; trackBy: trackByFn"
                    class="mb-3">
                    <div class="mt-2 mb-2">
                        <h3 class="font-weight-bold mb-1">{{model[i].documentName}}
                            <span *ngIf="applicationInDraft">({{model[i].isDocumentRequired ? 'Required' : 'Optional'}}{{model[i].documentAmountRequired > 1 ? ', ' + model[i].documentAmountRequired :null}})</span></h3>
                        <div [innerHtml]="model[i].documentDescription"></div>
                        <div *ngIf="!item.value.requestAuthority || !applicationInDraft" class="d-flex flex-row flex-wrap"
                            [ngClass]="{'': screenWidth>767, 'justify-content-center': screenWidth<768}">
                            <div *ngFor="let file of item.controls.files.controls; let j = index; trackBy: trackByFn"
                                class="mr-2 mt-2">
                                <app-entry-file [extension]="extensions" [file]="file" [canDelete]="true"
                                    [canUploadSameFile]="true" [dynamicUploading]="true"
                                    (onValueChange)="uploadDocument($event, model[i].documentTypeId, i, j, file.value.filename, file.id)"
                                    (onRemoveFile)="removeFile($event, i, j)"></app-entry-file>
                            </div>
                        </div>
                        <div class="d-flex" *ngIf="model[i].referenceRequired && item.value.requestAuthority && applicationInDraft">
                            <app-entry *ngIf="!item.value.previouslyProvided" [title]="model[i].referenceLabel"
                                [control]="item.controls.referenceValue"></app-entry>

                            <div *ngIf="item.value.previouslyProvided"  class="d-flex flex-row mt-1 mb-4">
                                <div class="d-flex flex-column">
                                    <span class="title">{{model[i].referenceLabel}}</span>
                                    <span class="content-text">{{item.value.referenceValue}}</span>
                                </div>
                                <div class="pl-2">
                                    <span class="clear-button" (click)="clearReferenceValue(item)">
                                        <mat-icon>edit</mat-icon>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="model[i].canRequestAuthority && applicationInDraft">
                            <div *ngIf="!checkFileUploaded(item.value.files)">
                                <p class="mt-3 mb-0" *ngIf="!item.value.requestAuthority">
                                    <i>
                                        You have the option to provide authority for us to
                                        access this document via
                                        <span *ngIf="applicationType.applicationTypeId === 10">Centrelink Confirmation eServices</span>
                                        <span *ngIf="applicationType.applicationTypeId === 11">ATO Online Services</span>
                                        instead of attaching the document. Please review and
                                        confirm the
                                        disclaimer below if you wish to do so.
                                    </i>
                                </p>
                                <div class="d-flex"
                                    [ngClass]="{'mt-1': item.value.requestAuthority, 'mt-3': !item.value.requestAuthority}">
                                    <app-checkbox [control]="item.controls.requestAuthority"></app-checkbox>
                                    <div [innerHtml]="model[i].documentDisclaimer"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="d-flex">
            <i class="px-3 my-1">
                All recommended documents and/or authority should be provided so your application can be assessed and
                processed in a timely manner.
            </i>
        </div>
        <div *ngIf="applicationInDraft" [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
            class="d-flex flex-wrap mt-4">
            <div [ngClass]="{'mt-1': screenWidth<768}">
                <app-button class="flex-fill" title="Exit" icon="{{'BTN.access-your-super.cancel.icon' | translate}}"
                    color="{{'BTN.access-your-super.cancel.color' | translate}}" (onClick)="exitForm()"></app-button>
            </div>
            <div [ngClass]="{'flex-row justify-content-between': screenWidth>767, 'flex-column-reverse': screenWidth<768}"
                class="d-flex">
                <app-button [ngClass]="{'mr-1': screenWidth>767, 'mt-1': screenWidth<768}" class="flex-fill {{'BTN.access-your-super.back.class' | translate}}"
                    title="Back" icon="{{'BTN.access-your-super.back.icon' | translate}}"
                    color="{{'BTN.access-your-super.back.color' | translate}}" (onClick)="goBack()"></app-button>
                <app-button [ngClass]="{'ml-1': screenWidth>767, 'mt-1': screenWidth<768}" class="flex-fill"
                    title="Next" icon="{{'BTN.access-your-super.continue.icon' | translate}}"
                    color="{{'BTN.access-your-super.continue.color' | translate}}"
                    [isDisabled]="form.isInvalid || (loading$|async)" (onClick)="goNextStep()"></app-button>
            </div>
        </div>
    </div>
</div>
