import {
  editinvestment_AllDisclaimerChecked,
  editinvestment_currentInfo, editinvestment_CurrentIsSum100Percent, editinvestment_CurrentStep,
  editinvestment_CurrentTotalPercent, editinvestment_Form, editinvestment_FutureIsSum100Percent, editinvestment_FutureTotalPercent,
  editinvestment_IsLastStep, editinvestment_LastModified, editinvestment_Steps, editinvestment_SubmitText, editinvestment_InvestmentCustomWording, editinvestment_FailedPerformanceMessage, editinvestment_IsSimpleSwitch, editinvestment_originalCurrentInfo
} from './selectors';
import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import {
  GoToStepAction, RequestAction, RequestCurrentDataAction, RequestOptionsDataAction,
  ResetAction, SetIsPensionAction, SetStepsAction, SubmitRequestAction
} from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { AppState } from '../../../../store/app.states';
import { MemberPortalSharedState } from '../../store/shared.states';
import { Router } from '@angular/router';
import { commomState_SystemConfig_Config, commomState_SystemConfig_FeatureToggle } from 'src/app/store/common/common.selectors';
import { CurrentInvestmentModel, InvestmentItemModel } from './state';
import { memberAccountDropdown_SelectedAccount } from '../member-account-dropdown/selectors';
import { Helper } from '@ifaa-components/ui-components';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { FeatureToggleName } from 'src/app/model/feature-toggle-name.model';
import { distinctUntilChanged } from 'rxjs/operators';
import { FormArrayState, FormGroupState, SetErrorsAction } from 'ngrx-forms';
import { stringify } from 'querystring';

@Component({
  selector: 'app-investment-edit',
  templateUrl: './investment-edit.component.html',
  styleUrls: ['./investment-edit.component.scss']
})
export class InvestmentEditComponent extends ComponentBase implements OnInit, OnDestroy {
  public _showOptions: boolean = true;
  @Input() set showOptions(showOptions: boolean) {
    if (showOptions)
      this.maxShow = -1;
    else {
      this.maxShow = 3;

      this._showOptions = showOptions;
    }
  };
  @Input() isDashboardView: boolean = false;

  updateInvestmentsFeatureToggle = FeatureToggleName.member.account.manageInvestments.update;

  form$ = this.store.pipe(select(editinvestment_Form));
  lastModified$ = this.store.pipe(select(editinvestment_LastModified));
  submitText$ = this.store.pipe(select(editinvestment_SubmitText));
  tenantConfig$ = this.appstore.pipe(select(commomState_SystemConfig_Config));
  featuretoggle$ = this.appstore.pipe(select(commomState_SystemConfig_FeatureToggle));
  currentStep$ = this.appstore.pipe(select(editinvestment_CurrentStep));
  failedPerformanceMessage$ = this.appstore.pipe(select(editinvestment_FailedPerformanceMessage));
  steps$ = this.appstore.pipe(select(editinvestment_Steps));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  currentInfo$ = this.store.pipe(select(editinvestment_currentInfo));
  originalCurrentInfo$ = this.store.pipe(select(editinvestment_originalCurrentInfo));
  currentIsSum100Percent$ = this.store.pipe(select(editinvestment_CurrentIsSum100Percent));
  currentTotalPercent$ = this.store.pipe(select(editinvestment_CurrentTotalPercent));
  isLastStep$ = this.store.pipe(select(editinvestment_IsLastStep));
  futureIsSum100Percent$ = this.store.pipe(select(editinvestment_FutureIsSum100Percent));
  futureTotalPercent$ = this.store.pipe(select(editinvestment_FutureTotalPercent));
  allDisclaimerChecked$ = this.store.pipe(select(editinvestment_AllDisclaimerChecked));
  customWording$ = this.store.pipe(select(editinvestment_InvestmentCustomWording));
  isSimpleSwitch$ = this.store.pipe(select(editinvestment_IsSimpleSwitch));
  accountId = 0;
  communicationPreference = null;
  submitText = '';
  helper = new Helper();
  isPensionAccount: boolean = false;
  steps: number[] = [];
  maxShow: number = -1;
  currentStep: number = 0;

  showFeesColumnToggle = FeatureToggleName.member.account.manageInvestments.showFees.view;
  myForm: string;

  constructor(public store: Store<MemberPortalSharedState>,
    public appstore: Store<AppState>,
    private bottomSheet: MatBottomSheet,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.sub = this.currentStep$.subscribe(x => {
      this.currentStep = x;
      window.scrollTo(0, 0);
    })
    this.sub = this.lastModified$.subscribe(async x => {
      if (x) {
        this.dispatch(this.store, GoToStepAction({ step: 4 }));
      }
    });

    this.sub = this.form$.subscribe(async form => {

      if (form) {
        if (JSON.stringify(form.value) == this.myForm) {
          return;
        }

        var data: FormArrayState<InvestmentItemModel> = null;
        var originalValues = await this.helper.getValue(this.originalCurrentInfo$);
        this.myForm = JSON.stringify(form.value);

        switch (this.currentStep) {
          case 1:
            //console.log('Current');
            data = form.controls.current as FormArrayState<InvestmentItemModel>;
            break;

          case 2:
            //console.log('Future');
            data = form.controls.future as FormArrayState<InvestmentItemModel>;

            break;

          default:
            return;

        }


        data.controls.forEach(element => {
          this.store.dispatch(new SetErrorsAction(element.controls.percentage.id, {}));

          if (element.value.percentage == 0) {
            return;
          }

          if (element.value.investmentOptionStatusId == 3) {

            var investmentOptions: InvestmentItemModel[] = null;
            switch (this.currentStep) {
              case 1:
                //console.log('Current');
                investmentOptions = originalValues.current?.filter(x => x.optionId == element.value.optionId);
                break;

              case 2:
                //console.log('Future');
                investmentOptions = originalValues.future?.filter(x => x.optionId == element.value.optionId);

                break;

              default:
                return;

            }
            if (investmentOptions?.length > 0) {
              if (element.value.percentage < 5) {
                var errors = {};
                errors['customError'] = 'Value must be greater than or equal to 5.';
                this.store.dispatch(new SetErrorsAction(element.controls.percentage.id, errors));
                return;
              }

              if (element.value.percentage > investmentOptions[0].percentage) {

                var errors = {};
                errors['customError'] = 'Allocation in this option cannot be more than the current (%) allocation.';
                this.store.dispatch(new SetErrorsAction(element.controls.percentage.id, errors));
              }
              else {
                this.store.dispatch(new SetErrorsAction(element.controls.percentage.id, {}));
              }
            }

          }
          else {
            if (element.value.percentage < 5) {
              var errors = {};
              errors['customError'] = 'Value must be greater than or equal to 5.';
              this.store.dispatch(new SetErrorsAction(element.controls.percentage.id, errors));
            }
          }
        });

      }
    });



    this.sub = this.submitText$.subscribe(async x => {
      if (x) {
        this.submitText = x;
      }
    });


    this.sub = this.selectedAccount$.pipe(distinctUntilChanged((x, y) => x.accountId === y?.accountId)).subscribe(x => {
      if (x) {
        this.dispatch(this.store, RequestAction());

        this.isPensionAccount = x.accountTypeName == 'Pension' || x.isTtrType;
        this.accountId = x.accountId;
        this.dispatch(this.store, SetIsPensionAction({ payload: this.isPensionAccount }));
      }
    });

    this.sub = this.steps$.subscribe(x => {
      this.steps = x;
    });
  }

  onGoToDashboardClick() {
    this.bottomSheet.dismiss();
    this.router.navigate(['/']);
  }

  getCurrentPercentage(option: string, current: InvestmentItemModel[]): number {
    var s = current?.filter(x => x.option == option);
    if (s?.length > 0) {
      return s[0].percentage;
    }
    return 0;
  }

  getCurrentAmount(option: string, current: InvestmentItemModel[]) {
    var s = current?.filter(x => x.option == option);
    if (s?.length > 0)
      return s[0].amount;
    return 0;
  }

  trackByFn(index, item) {
    return index;
  }

  ngOnDestroy() {
    this.store.dispatch(ResetAction());
    super.ngOnDestroyBase();
  }

  async onSubmitClick(data: CurrentInvestmentModel) {
    var isSimpleSwitch = await this.helper.getValue(this.isSimpleSwitch$);
    var dataClone = this.helper.clone(data);

    // if its simple switch we need to assign current allocation to future
    if (isSimpleSwitch) {
      dataClone.future = dataClone.current;
    }

    this.dispatch(this.store, SubmitRequestAction({ accountId: this.accountId, obj: dataClone }));
  }

  async onNextItemClick() {
    var current = await this.helper.getValue(this.currentStep$);
    var i = this.steps.indexOf(current);
    this.dispatch(this.store, GoToStepAction({ step: this.steps[i + 1] }));
  }

  async onBackItemClick() {
    var current = await this.helper.getValue(this.currentStep$);
    var i = this.steps.indexOf(current);
    this.dispatch(this.store, GoToStepAction({ step: this.steps[i - 1] }));
  }

}
