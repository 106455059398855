import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FeatureToggleName } from "src/app/model/feature-toggle-name.model";
import { ComponentBase } from "src/app/views/component-base";
import { MemberPortalSharedState } from "../../../store/shared.states";
import { AppState } from "src/app/store/app.states";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { Store, select } from "@ngrx/store";
import { Router } from "@angular/router";
import { GoToStepAction, RequestAction, RequestCurrentDataAction, RequestOptionsDataAction, SetIsPensionAction, SetStepsAction, SetSubmitText } from "../actions";
import { editinvestment_CurrentStep, editinvestment_LastModified, editinvestment_currentInfo } from "../selectors";
import { commomState_SystemConfig_Config, commomState_SystemConfig_FeatureToggle } from "src/app/store/common/common.selectors";
import { memberAccountDropdown_SelectedAccount } from "../../member-account-dropdown/selectors";
import { Helper } from "@ifaa-components/ui-components";
import { CurrentInvestmentModel, InvestmentItemModel } from "../state";

@Component({
    selector: 'app-investment-complex-view',
    templateUrl: './investment-complex-view.component.html',
    styleUrls: ['./investment-complex-view.component.scss']
})
export class InvestmentComplexViewComponent extends ComponentBase implements OnInit, OnDestroy {
    public _showOptions: boolean = true;
    @Input() set showOptions(showOptions: boolean) {
      if (showOptions)
        this.maxShow = -1;
      else {
        this.maxShow = 3;
  
        this._showOptions = showOptions;
      }
    };
    @Input() isDashboardView: boolean = false;
  
    updateInvestmentsFeatureToggle = FeatureToggleName.member.account.manageInvestments.update;

    lastModified$ = this.store.pipe(select(editinvestment_LastModified));
    tenantConfig$ = this.appstore.pipe(select(commomState_SystemConfig_Config));
    featuretoggle$ = this.appstore.pipe(select(commomState_SystemConfig_FeatureToggle));
    currentStep$ = this.appstore.pipe(select(editinvestment_CurrentStep));
    selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
    currentInfo$ = this.store.pipe(select(editinvestment_currentInfo));
    accountId = 0;
    submitText = '';
    helper = new Helper();
    isPensionAccount: boolean = false;
    steps: number[] = [];
    maxShow: number = -1;
  
    constructor(public store: Store<MemberPortalSharedState>,
      public appstore: Store<AppState>,
      private bottomSheet: MatBottomSheet,
      private router: Router) {
      super();
    }
  
    ngOnInit() {
      super.ngOnInitBase();
      this.sub = this.lastModified$.subscribe(async x => {
        if (x) {
          this.dispatch(this.store, GoToStepAction({ step: 4 }));
        }
      });
  
      this.sub = this.selectedAccount$.subscribe(x => {
        if (x) {
          this.dispatch(this.store, RequestAction());
  
          this.isPensionAccount = x.accountTypeName == 'Pension' || x.isTtrType;
          this.accountId = x.accountId;
          this.dispatch(this.store, SetIsPensionAction({ payload: this.isPensionAccount }));
          this.dispatch(this.store, RequestCurrentDataAction({ account: x.accountId }));
          this.dispatch(this.store, RequestOptionsDataAction({ account: x.accountId }));
        }
      });
    }
    filterCurrent(list) {
  
      if (!list)
        return list;
  
      if (this.maxShow == 3) {
        return list.slice(0, 3);
      }
      else {
        return list;
      }
    }
  
    filterFuture(list: any[]) {
      if (!list)
        return list;
      list = list.filter(o => o.percentage > 0);
      if (this.maxShow == 3) {
        return list.slice(0, 3);
      }
      else {
        return list;
      }
    }

    getAsAtBalanceDate(data: CurrentInvestmentModel) {
        if (data.current.length > 0)
          return data.current[0].asAt;
        return '';
      }
    
      trackByFn(index, item) {
        return index;
      }

    onEditItemClick(step: number, steps: number[], isDisabled: boolean) {
        if (isDisabled)
          return;
    
        this.setupMessage(step, steps);
    
        this.dispatch(this.store, GoToStepAction({ step }));
        if (steps.length > 0) {
          this.steps = steps;
          this.dispatch(this.store, SetStepsAction({ steps }));
        }
      }

      setupMessage(step: number, steps: number[]) {
        if (steps.length == 3) {
          if (step == 1) {
            if (this.isPensionAccount) {
              this.submitText = `<p>We have received your request to update your current investment balance.</p>
                                <p>This will be processed when the unit price for today is advised (Approx. 2 business days).</p>
                                <p>You will be notified when the investment switch has been processed.</p>`;
            }
            else {
              this.submitText = `<p>We have received your request to update your current investment balance.</p>
                                <p>This will be processed when the unit price for today is advised (Approx 2 business days).</p>
                                <p>You will be notified when the investment switch has been processed.</p>`;
            }
            this.dispatch(this.store, SetSubmitText({ submitText: this.submitText }));
          }
          if (step == 2) {
            if (this.isPensionAccount) {
              this.submitText = `<p>We have received your request to update your future pension payment drawdown.</p>
                                  <p>This will be actioned immediately.</p>`;
            }
            else {
              this.submitText = `<p>We have received your request to update your future contribution and rollover investments.</p>
                                  <p>This will be actioned immediately.</p>`;
            }
            this.dispatch(this.store, SetSubmitText({ submitText: this.submitText }));
          }
          return;
    
        }
        if (steps.length == 4) {
          if (this.isPensionAccount) {
            this.submitText = `<p>We have received your request to update your current investment options and your future pension payment drawdown.</p>
                              <p>Any change to the future pension payments will be effective immediately.</p>
                              <p>Any change to your investment options will be processed when the unit price for today is advised (Approx 2 business days).</p>
                              <p>You will be notified when the investment switch has been processed.</p>`;
          }
          else {
            this.submitText = `<p>We have received your request to update your current investment balance and your future contributions.</p>
                                <p>Any change to the future contributions will be effective immediately.</p>
                                <p>Any change to your investment balance (switch) will be processed when the unit price for today is advised (Approx 2 business days).</p>
                                <p>You will be notified when the investment switch has been processed.</p>`;
          }
          this.dispatch(this.store, SetSubmitText({ submitText: this.submitText }));
        }
      }
      
      ngOnDestroy() {
        super.ngOnDestroyBase();
      }
    
      onShowMoreOrLess() {
        this.maxShow = this.maxShow == 3 ? -1 : 3;
      }
}