<div id="member-account-dropdown-container" class="dropdown">
  <button class="btn btn-light  d-flex align-items-center" type="button" id="dropdownMenuButton" data-toggle="dropdown"
    aria-haspopup="true" aria-expanded="false" [ngClass]="{'dropdown-toggle': (list$ | async)?.length > 1}">
    <!-- <mat-icon class="mr-3">how_to_reg</mat-icon> -->

    <div class="mr-2">
      <label class="selected-info member align-items-start justify-content-start d-flex">
        <div>{{'Member number' | textTransform}}:</div> {{(loggedIn$ | async)?.memberNumber}}
      </label>
      <label class="selected-info account align-items-start justify-content-start d-flex">
        <div>{{'Account number' | textTransform}}:</div> {{(selected$ | async)?.accountNumber}}
      </label>
    </div>
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton" *ngIf="(list$ | async)?.length > 1">
    <li *ngFor="let item of list$ | async"
      class="{{item.accountId == (selected$ | async)?.accountId ? 'selected-account' : ''}}">
      <a class="dropdown-item d-flex p-2" (click)="onSelecteAccountClick(item)">
        <mat-icon class="mr-3">chevron_right</mat-icon>
        <div>
          <label class="label-info"><span class="account-info">Account number: </span> {{item.accountNumber}}</label>
          <label class="label-info"><span class="account-info">Account type: </span> {{item.accountTypeName}}</label>
        </div>
      </a>
    </li>
  </ul>
</div>
